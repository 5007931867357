<template>
  <div class="subscription-success">
    <h3 class="title is-3">{{ $t('subscription.thanks') }}</h3>
    <div v-if="$store.getters.hasStandardAccess">
      <b-button type="is-primary" @click="generatePlan" tag="router-link" to="/generate_training_plan"> {{ $t('plan.generate_training_plan') }}</b-button>
    </div>
    <div v-else>
      <div v-html="$t('subscription.being_treated')"/>
    </div>
    <div class="mt-5">
      <ManageBilling/>
    </div>

  </div>
</template>

<script>
import ManageBilling from '@/components/ManageBilling.vue';

export default{
  name: 'subscription-success',
  data(){
    return {
      sessionID: null,
    };
  },
  mounted(){
    this.sessionID = this.$route.query.session_id;
  },
  components: {
    ManageBilling,
  },
};
</script>

<style lang="scss" scoped>
</style>
